import React, { useCallback } from "react";
import _, { isEmpty } from "lodash";
import Card from "../Card";
import GiftSmallIcon from "../icons/Gift.svg";
import CalendarIcon from "../icons/Calendar.svg";
import { Price } from "../PriceLine";
import moment from "../../utils/moment-timezone-with-data-2012-2022";

export default function CouponBatches({
  T,
  batches = [],
  couponInstances = [],
  couponsBatchIndex,
  appStyles,
  currencySymbol,
  onChange,
}) {
  const limit = 10;
  if (isEmpty(batches)) {
    return null;
  }
  const { couponInstanceIds, couponInstanceIdsToDiscount } =
    couponsBatchIndex > -1
      ? batches[couponsBatchIndex]
      : { couponInstanceIds: [], couponInstanceIdsToDiscount: [] };
  return (
    <Card appStyles={appStyles}>
      <Card.Title light appStyles={appStyles}>
        <GiftSmallIcon /> {T("Rewards")}
      </Card.Title>
      <Card.Content>
        <Card.Content.CheckboxGroup
          name={T("Rewards")}
          limit={limit < 0 ? undefined : limit}
          checked={couponInstanceIds}
          onChange={(checked) => onChange({ checked })}
          appStyles={appStyles}
        >
          {_.map(couponInstances, (couponInstance) => (
            <Card.Content.CheckboxOption
              iconSize={16}
              iconInnerSize={16}
              pointColor={appStyles.accentColor}
              value={couponInstance.id}
              key={couponInstance.id}
            >
              <span style={{ paddingTop: 4, width: "100%" }}>
                <span
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>{T(couponInstance.coupon.title)}</span>
                  <Price
                    discount
                    value={getDiscountAmount(
                      couponInstance.id,
                      couponInstanceIdsToDiscount,
                    )}
                    currencySymbol={currencySymbol}
                  />
                </span>
                <span>
                  <small style={{ color: "#555" }}>
                    {T(couponInstance.coupon.description)}
                  </small>
                </span>
                <br />
                <span>
                  <small style={{ color: "#555" }}>
                    {couponInstance.expirationDate && (
                      <span>
                        <CalendarIcon />
                        {` ${T("Valid until:")} ` +
                          moment(
                            new Date(couponInstance.expirationDate),
                          ).format(
                            appStyles.giftsExpirationDateFormat || "MMM Do YY",
                          )}
                      </span>
                    )}
                  </small>
                </span>
              </span>
            </Card.Content.CheckboxOption>
          ))}
        </Card.Content.CheckboxGroup>
      </Card.Content>
    </Card>
  );
}

const getDiscountAmount = (couponInstanceId, couponInstanceIdsToDiscount) => {
  const couponDiscount = _.find(
    couponInstanceIdsToDiscount,
    (cIToD) => cIToD.couponInstanceId === couponInstanceId,
  );
  return couponDiscount ? couponDiscount.discountAmount : null;
};
