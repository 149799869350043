import React, { useEffect, useState } from "react";
import classnames from "classnames";
import styles from "./index.module.scss";

export default ({ appStyles, color, loading, checked }) => {
  // const [showChecked, setShowChecked] = useState(checked);
  // useEffect(() => {
  //   if (!loading && checked && !showChecked) {
  //     setShowChecked(true);
  //   }
  // }, [loading, checked]);
  return (
    <span
      style={{ color: color || appStyles.inputValidColor }}
      className={classnames(
        loading ? styles.Loading : checked && styles.AnimatedValidCheckmark,
        // appStyles.rtl && styles.RTL,
      )}
    >
      {
        <span
          className={classnames(
            ...((!loading && checked && [styles.Checkmark, styles.Draw]) || []),
          )}
        />
      }
    </span>
  );
};
