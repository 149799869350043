import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get, map, capitalize, noop } from "lodash";

import {
  getExpirationDateString,
  humanReadablePaymentTypeString,
} from "../../utils/payment-method";
import {
  List,
  ListItem,
  Chip,
  ListItemSecondaryAction,
  ListItemText,
  CircularProgress,
  Grid,
  Box,
  Typography,
  Button,
} from "@material-ui/core";

export default function PaymentMethodsSelect({
  pageContext,
  T,
  appStyles,
  onChange,
}) {
  const user = useSelector(({ user }) => user);

  const { paymentMethods, defaultPaymentMethodToken } = user;
  const handleSelected = useCallback(
    (token) => () => {
      onChange(token);
    },
    [onChange],
  );

  if (paymentMethods.loading) {
    return (
      <Box textAlign="center" margin={6}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  console.log({ defaultPaymentMethodToken });

  return (
    <>
      {get(paymentMethods.data, "length") && (
        <Box padding={2}>
          <Typography variant="subtitle1">
            {T("Select Payment Method:")}
          </Typography>
        </Box>
      )}

      <List>
        {map(paymentMethods.data, (paymentMethod) => (
          <ListItem>
            <ListItemText
              primary={humanReadablePaymentTypeString(paymentMethod.typeString)}
              secondary={
                <Grid continer direction="column">
                  <Grid item>
                    {`${T("Card Number (Last 4 digits):")}`}
                    <code>{paymentMethod.displayString}</code>
                  </Grid>
                  <Grid item>
                    {`${T("Expiration Date:")}`}
                    <code>{getExpirationDateString(paymentMethod)}</code>
                  </Grid>
                </Grid>
              }
            />
            {defaultPaymentMethodToken === paymentMethod.token ? (
              <Chip label={T("Seleced")} color="secondary" />
            ) : (
              <ListItemSecondaryAction>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleSelected(paymentMethod.token)}
                >
                  {T("Select")}
                </Button>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
    </>
  );
}
