import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Button, Box, Grid, Typography } from "@material-ui/core";

import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { noop } from "lodash";

export default function PendingCheckDrawer({
  open,
  pendingCheck,
  onClose,
  onOpenCheckDetails,
}) {
  const classes = useStyles();
  if (!pendingCheck) {
    return null;
  }
  return (
    <Drawer
      className={clsx(!open && classes.rootClosed)}
      variant="persistent"
      anchor={"bottom"}
      open={open}
      onClose={onClose}
      onOpen={noop}
      elevation={2}
    >
      <Box padding={2}>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h6">
              You've got an order at {pendingCheck.branch.name}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onOpenCheckDetails}
            >
              Open and pay
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}

const useStyles = makeStyles({
  rootClosed: {
    pointerEvents: "none",
  },
  list: {
    width: "auto",
  },
});
